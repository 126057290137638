@font-face {
  font-family: "Dystopian";
  font-weight: 800;
  src: url(../fonts/Dystopian/DystopianBold.ttf) format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "Dystopian";
  src: url(../fonts/Dystopian/DystopianRegular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
