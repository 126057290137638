* {
  user-select: none;
}

.react-colorful__saturation-pointer {
  width: 20px !important;
  height: 20px !important;
  border-radius: 0 !important;
}

.react-colorful__hue {
  height: 20px !important;
  margin-top: 0.5rem;
  border-radius: 0 !important;
}

.react-colorful__pointer .react-colorful__hue-pointer {
  width: 20px;
  height: 20px;
  border-radius: 0 !important;
}

.react-colorful__hue .react-colorful__hue-pointer {
  width: 20px;
  height: 20px;
  border-radius: 0 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/***************************************************
 * Loading Logo Light
 **************************************************/

@-webkit-keyframes animate-svg-fill-light-1 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

@keyframes animate-svg-fill-light-1 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

.logo-light-box-1 {
  -webkit-animation: animate-svg-fill-light-1 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0s both;
  animation: animate-svg-fill-light-1 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0s both;
}

@-webkit-keyframes animate-svg-fill-light-2 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

@keyframes animate-svg-fill-light-2 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

.logo-light-box-2 {
  -webkit-animation: animate-svg-fill-light-2 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0.1s both;
  animation: animate-svg-fill-light-2 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0.1s both;
}

@-webkit-keyframes animate-svg-fill-light-3 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

@keyframes animate-svg-fill-light-3 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

.logo-light-box-3 {
  -webkit-animation: animate-svg-fill-light-3 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0.2s both;
  animation: animate-svg-fill-light-3 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0.2s both;
}

@-webkit-keyframes animate-svg-fill-light-4 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

@keyframes animate-svg-fill-light-4 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

.logo-light-box-4 {
  -webkit-animation: animate-svg-fill-light-4 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0.30000000000000004s both;
  animation: animate-svg-fill-light-4 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0.30000000000000004s both;
}

@-webkit-keyframes animate-svg-fill-light-5 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

@keyframes animate-svg-fill-light-5 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

.logo-light-box-5 {
  -webkit-animation: animate-svg-fill-light-5 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0.4s both;
  animation: animate-svg-fill-light-5 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0.4s both;
}

@-webkit-keyframes animate-svg-fill-light-6 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

@keyframes animate-svg-fill-light-6 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

.logo-light-box-6 {
  -webkit-animation: animate-svg-fill-light-6 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0.5s both;
  animation: animate-svg-fill-light-6 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0.5s both;
}

@-webkit-keyframes animate-svg-fill-light-7 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

@keyframes animate-svg-fill-light-7 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

.logo-light-box-7 {
  -webkit-animation: animate-svg-fill-light-7 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6000000000000001s both;
  animation: animate-svg-fill-light-7 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6000000000000001s both;
}

@-webkit-keyframes animate-svg-fill-light-8 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

@keyframes animate-svg-fill-light-8 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

.logo-light-box-8 {
  -webkit-animation: animate-svg-fill-light-8 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0.7000000000000001s both;
  animation: animate-svg-fill-light-8 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0.7000000000000001s both;
}

/***************************************************
 * Loading Logo Dark
 **************************************************/

@-webkit-keyframes animate-svg-fill-dark-1 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

@keyframes animate-svg-fill-dark-1 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

.logo-dark-box-1 {
  -webkit-animation: animate-svg-fill-dark-1 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0s both;
  animation: animate-svg-fill-dark-1 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0s both;
}

@-webkit-keyframes animate-svg-fill-dark-2 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

@keyframes animate-svg-fill-dark-2 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

.logo-dark-box-2 {
  -webkit-animation: animate-svg-fill-dark-2 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0.1s both;
  animation: animate-svg-fill-dark-2 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0.1s both;
}

@-webkit-keyframes animate-svg-fill-dark-3 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

@keyframes animate-svg-fill-dark-3 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

.logo-dark-box-3 {
  -webkit-animation: animate-svg-fill-dark-3 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0.2s both;
  animation: animate-svg-fill-dark-3 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0.2s both;
}

@-webkit-keyframes animate-svg-fill-dark-4 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

@keyframes animate-svg-fill-dark-4 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

.logo-dark-box-4 {
  -webkit-animation: animate-svg-fill-dark-4 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0.30000000000000004s both;
  animation: animate-svg-fill-dark-4 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0.30000000000000004s both;
}

@-webkit-keyframes animate-svg-fill-dark-5 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

@keyframes animate-svg-fill-dark-5 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

.logo-dark-box-5 {
  -webkit-animation: animate-svg-fill-dark-5 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0.4s both;
  animation: animate-svg-fill-dark-5 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0.4s both;
}

@-webkit-keyframes animate-svg-fill-dark-6 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

@keyframes animate-svg-fill-dark-6 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

.logo-dark-box-6 {
  -webkit-animation: animate-svg-fill-dark-6 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0.5s both;
  animation: animate-svg-fill-dark-6 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0.5s both;
}

@-webkit-keyframes animate-svg-fill-dark-7 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

@keyframes animate-svg-fill-dark-7 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

.logo-dark-box-7 {
  -webkit-animation: animate-svg-fill-dark-7 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6000000000000001s both;
  animation: animate-svg-fill-dark-7 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6000000000000001s both;
}

@-webkit-keyframes animate-svg-fill-dark-8 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

@keyframes animate-svg-fill-dark-8 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

.logo-dark-box-8 {
  -webkit-animation: animate-svg-fill-dark-8 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0.7000000000000001s both;
  animation: animate-svg-fill-dark-8 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0.7000000000000001s both;
}
